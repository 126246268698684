<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :scroll="{x: true}" :columns="columns"
			:get_table_list="this.get_table_list" :slot_table_list="slot_table_list" :is_page="false" ref="list" @changeStatus='changeStatus'
			rowKey="id" :where='where' :is_created_get_list="false" :submit_preprocessing="submit_preprocessing">
			<template slot="form_after">
				<div class='page_content_top page_content'>
					<div v-for='item in tracing_count'>
						<a-statistic :title="item.label" :value="item.value" />
					</div>
				</div>
			</template>
			<template slot="op" slot-scope="data">
				<a  @click='go_code(data.record)'  target="_blank">
				查看溯源码
				</a>
			</template>
			<template slot="before">
				<div class="table_before">
					<div class="left">

					</div>
					<div class="right_btn" style='text-align: right;'>
						<a :href="'/manage/tracing.statistics/stasiticsExport','type=3' | export_form_seo" target="_blank">
							<a-button class="a_btn">导出</a-button>
						</a>
					</div>
				</div>
			</template>
		</TableList>
	</div>
</template>
<script>
	import TableList from "@/components/TableList";
	import {
		orderReport,
		orderReportSub
	} from "@/api/order";
	import {
		getStatisticsTop,
		getStatisticsList,
		getWarehouseSelect,
		getFactorySelect,
		getLinseSelect
	} from "@/api/traceability";

	let columns = [{
			title: "产线名称",
			dataIndex: "line_name",

		},
		{
			title: "基地",
			dataIndex: "factory_sap_name",
		},
		{
			title: '基地地址',
			dataIndex: "storage_address",

		},
		{
			title: '出货总量',
			dataIndex: "total_num",

		},
		{
			title: '操作',
			dataIndex: "op",
		scopedSlots: {
			customRender: "op"
		},
		},
		
	];
	export default {
		name: "Index",
		components: {
			TableList,
		},
		data() {
			return {
				where: {
					type: 3,
					factory_sap_id: ''
				},
				
				tracing_count: [],
				get_table_list: getStatisticsList,
				submit_preprocessing: {
					array_to_string: ['department', 'goods_ids', 'customer_type', 'role']
				},

				slot_table_list: ['op'],
				columns,
				goods_list: [],
				drawer_visible: false,

				form_data_seo: {
					...this.$config.form_data_seo,
					list: [
						{
							type: "hidden",
							name: "type",
							options: {
								initialValue:3
							},
							list: []
						},
						{
							type: "select",
							name: "prd_line",
							title: "产线",
							options: {},
							list: []
						},
						{
							type: "date",
							name: "start_time",
							title: "开始日期",
							options: {},
						},
						{
							type: "date",
							name: "end_time",
							title: "结束日期",
							options: {},
						},
					],

				},
				form_data: {}
			};
		},
		async created() {
			this.where.factory_sap_id = this.$route.query.factory_sap_id;

			this.$nextTick(() => {
				this.$refs.list.get_list();
			})
			this.get_Statistics_Top()
			this.get_option()
		},
		mounted() {},
		methods: {
			go_code(info){
				this.$keep_route.add(this.$options.name);
				this.$router.push({
					path:"/traceability/code",
					query:{
						factory_sap_id:info.factory_sap_id,
						line_id:info.line_id,
						status:2
					}
				});
			},
			get_option() {
				// 产线数据
				getLinseSelect({
					data: {
						...this.where
					}
				}).then(res => {
					this.$method.set_form_list(this.form_data_seo.list, 'prd_line', 'list', res.data.list);
				})
			},
			changeStatus(data){
				this.get_Statistics_Top(data)
			},
			get_Statistics_Top(data) {
				getStatisticsTop({
					data: {
						...this.where,
						...data,
						type:3
					}
				}).then(res => {
					this.tracing_count = res.data.list
				})
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.visit_img {
		width: 60px;
		height: 60px;
	}

	.page_content_top {
		display: flex;

		div {
			flex: 1;
			text-align: center;
		}
	}
</style>